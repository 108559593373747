<template>
  <div class="payment">
    <div>
      <div class="payment-instruction mt-5">
        <h1
          class="font-weight-bold fs-24 mb-2 payment-title"
        >
          PRE-APPROVAL AGREEMENT FOR AUTOFLIP TO BE GIVEN AUTHORITY TO DEBIT
        </h1>
        <p class="payment-content font-weight-thin">
          By completing this form, you agree for AutoFlip to debit using your chosen method of payment. 
          Upon completion, you will be sent a confirmation by email together with instructions on how to cancel this pre-approval if required. 
          You will only be charged your Success Fee (or Cancellation Fee, if applicable)
        </p>
        <div class="payment-note">
          <p class="heading text-center mb-2">IMPORTANT INFORMATION</p>
          <ul>
            <li>You WILL NOT be charged anything immediately; your payment will be deducted in 7 days.</li>
            <li>Remember, according to our <a style="color: #000000" href="https://www.autoflip.com.au/seller-agreement" target="_blank"><u>seller agreement</u></a>, cancelling the deal incurs a $198 cancellation fee.</li>
          </ul>
        </div>
      </div>
      <div class="payment-info mt-3">
        <SectionDivider dividerText="CONTACT INFO"></SectionDivider>
          <b-row>
            <b-col cols="12" md="6">
              <custom-input
                name="firstName"
                label="First name"
                placeholder="Enter your first name"
                :helperText="firstNameErrorMessage"
                v-model="contactInfo.firstName"
                :error="$v.contactInfo.firstName.$error"
                maxlength="110"
              />
            </b-col>
            <b-col cols="12" md="6">
              <custom-input
                name="lastName"
                label="Last name"
                placeholder="Enter your last name"
                :helperText="lastNameErrorMessage"
                v-model="contactInfo.lastName"
                :error="$v.contactInfo.lastName.$error"
                maxlength="110"
              />
            </b-col>
            <b-col cols="12" md="6">
              <custom-input
                name="addressLine1"
                label="Address line 1"
                placeholder="Enter your address line 1"
                :helperText="addressLine1ErrorMessage"
                v-model="contactInfo.addressLine1"
                :error="$v.contactInfo.addressLine1.$error"
                maxlength="110"
              />
            </b-col>
            <b-col cols="12" md="6">
              <custom-input
                name="addressLine2"
                label="Address line 2 (Optional)"
                placeholder="Enter your address line 2"
                v-model="contactInfo.addressLine2"
              />
            </b-col>
            <b-col cols="12" md="6">
              <custom-input
                name="suburb"
                label="Suburb"
                placeholder="Enter your suburb"
                :helperText="suburbErrorMessage"
                v-model="contactInfo.suburb"
                :error="$v.contactInfo.suburb.$error"
                maxlength="110"
              />
            </b-col>
            <b-col cols="12" md="6">
              <custom-input
                name="state"
                label="State"
                placeholder="Enter your state"
                :helperText="stateErrorMessage"
                v-model="contactInfo.state"
                :error="$v.contactInfo.state.$error"
                maxlength="110"
              />
            </b-col>
            <b-col cols="12" md="6">
              <custom-input
                name="postcode"
                label="Postcode"
                placeholder="Enter your postcode"
                :helperText="postcodeErrorMessage"
                v-model="contactInfo.postcode"
                :error="$v.contactInfo.postcode.$error"
                type="number"
                max="4"
              />
            </b-col>
            <b-col cols="12" md="6"> 
              <custom-input
                name="email"
                label="Email"
                placeholder="Enter your email address"
                :helperText="emailErrorMessage"
                v-model="contactInfo.email"
                :error="$v.contactInfo.email.$error || !isCorrectEmailDomain"
                maxlength="256"
              />
              <p class="fs-14 mb-0 font-weight-thin" style="color: #8E8E8E">Use the same email address as your AutoFlip enquiry.</p>
            </b-col>
          </b-row>
        <SectionDivider dividerText="PAYMENT DETAILS"></SectionDivider>
        <b-alert v-if="$v.paymentInfo.cardNumber.$error && paymentInfo.cardNumber.length > 0 || isShowAuthorisedErrMsg || isShowExpiryDateErrMsg" show variant="danger">
          <div class="alert-container">
            <div class="d-flex align-items-center justify-content-center">
              <div class="align-self-start mr-2">
                <custom-icon name="danger" width="100%" :m0="true" />
              </div>
              <p class="mb-0 alert-text">
                {{ $v.paymentInfo.cardNumber.$error && paymentInfo.cardNumber.length > 0 
                    ? cardNumberErrMsg 
                    : isShowAuthorisedErrMsg
                      ? authorisedErrMsg
                      : expiryDateErrMsg
                }}
              </p>
            </div>
          </div>
        </b-alert>
        <b-row>
          <b-col cols="12" md="12">
            <custom-input
              name="cardNumber"
              label="Card Number (no spaces or dashes)"
              placeholder="Card number"
              :helperText="cardNumberErrorMessage"
              v-model.trim="paymentInfo.cardNumber"
              :error="$v.paymentInfo.cardNumber.$error"
              type="number"
              max="16"
            />
          </b-col>
          <b-col cols="12" md="4">
            <custom-input
              name="securityCode"
              label="Security Code"
              placeholder="Enter your security code"
              :helperText="securityCodeErrorMessage"
              v-model="paymentInfo.securityCode"
              :error="$v.paymentInfo.securityCode.$error"
              type="number"
              max="4"
            />
          </b-col>
          <b-col cols="12" md="4">
            <label for="expiryMonth" class="mb-0">Expiry month</label>
              <custom-select
                name="expiryMonth"
                v-model="paymentInfo.expiryMonth"
                :options="months"
                helperText="Please select your expiry month"
                :error="$v.paymentInfo.expiryMonth.$error"
              />
          </b-col>
          <b-col cols="12" md="4">
            <custom-input
              name="expiryYear"
              label="Expiry year"
              placeholder="YYYY"
              :helperText="expiryYearErrorMessage"
              v-model="paymentInfo.expiryYear"
              :error="$v.paymentInfo.expiryYear.$error"
              type="number"
              max="4"
            />
          </b-col>
          <b-col cols="12" md="12">
            <custom-input
              name="cardName"
              label="Name (as it appears on your credit card)"
              placeholder="Enter your name as it appears on your card"
              :helperText="cardNameErrorMessage"
              v-model="paymentInfo.cardName"
              :error="$v.paymentInfo.cardName.$error"
              maxlength="110"
            />
          </b-col>
        </b-row>
      </div>
      <button 
        class="default-btn-confirm"
        :class="activeConfirmBtn ? 'btn-confirm-active' : 'btn-confirm'"
        @click="onSubmit"
      >
        <b-spinner v-if="isLoadingApi" label="Loading..." class="btn-loading"></b-spinner>
        <span v-else>Confirm agreement</span>
      </button>
      <p class="payment-contact mt-3 fs-16 mb-5">If you need any help or have questions, please do not hesitate to contact us at
        <a :href="`tel:${contactDetails.phoneNumber}`"><u>{{ contactDetails.phoneNumber }}</u></a> 
        or check out our
        <a href="https://www.autoflip.com.au/success-fee-payment-faq" target="_blank"><u> payment FAQ page</u></a>.
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  required,
  minLength,
  maxLength,
  numeric,
  helpers,
  email,
  between,
  requiredIf,
} from "vuelidate/lib/validators";
import {
  SET_PAYMENT_INFO,
  SET_BUYERS_PORTAL_MAIL,
} from "@/store/payment/actions";
import PaymentService from "@/services/payment";
import { GET_NZ_VARIABLES } from "@/store/common-variables/actions";
import { 
  isValid,
  isExpirationDateValid,
} from 'creditcard.js';
import { getCookie } from "@/helper/cookie";
import { decrypt } from "@/helper/utils";
import customerDetailService from "@/services/customer-detail";
import debounce from "lodash/debounce";

const regexName = helpers.regex("name", /^[a-zA-Z0-9 ]+$/);

export default {
  name: "Index",
  components: {
    SectionDivider: () => import("../../components/common/SectionDivider.vue"),
    CustomSelect: () => import("../../components/common/CustomSelect.vue"),
    CustomIcon: () => import("../../components/common/CustomIcon"),
    CustomInput: () => import("../../components/common/CustomInput"),
  },
  data() {
    return {
      isShowAuthorisedErrMsg: false,
      isShowExpiryDateErrMsg: false,
      isLoadingApi: false,
      cardNumberErrMsg: 'You have entered an invalid card number.',
      authorisedErrMsg: 'This credit card could not be authorised. Please try again with a valid card that has sufficient funds.',
      expiryDateErrMsg: 'You have entered an invalid expiry date.',
      isCorrectEmailDomain: true,
    }
  },
  computed: {
    ...mapState({
      payment: (state) => state.payment,
      contactInfo: (state) => state.payment.contactInfo,
      paymentInfo: (state) => state.payment.paymentInfo,
      token: (state) => state.payment.token,
      contactDetails: (state) => state.commonVariables.contactDetails,
    }),
    months() {
      return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    },
    firstNameErrorMessage() {
      return this.getContactFieldErrorMessage('firstName');
    },
    lastNameErrorMessage() {
      return this.getContactFieldErrorMessage('lastName');
    },
    addressLine1ErrorMessage() {
      return this.getContactFieldErrorMessage('addressLine1');
    },
    suburbErrorMessage() {
      return this.getContactFieldErrorMessage('suburb');
    },
    stateErrorMessage() {
      return this.getContactFieldErrorMessage('state');
    },
    postcodeErrorMessage() {
      return this.getContactFieldErrorMessage('postcode');
    },
    emailErrorMessage() {
      return this.getContactFieldErrorMessage('email');
    },
    cardNumberErrorMessage() {
      return this.getPaymentFieldErrorMessage('cardNumber');
    },
    securityCodeErrorMessage() {
      return this.getPaymentFieldErrorMessage('securityCode');
    },
    expiryMonthErrorMessage() {
      return this.getPaymentFieldErrorMessage('expiryMonth');
    },
    expiryYearErrorMessage() {
      return this.getPaymentFieldErrorMessage('expiryYear');
    },
    cardNameErrorMessage() {
      return this.getPaymentFieldErrorMessage('cardName');
    },
    activeConfirmBtn: function() {
      return (
        this.contactInfo.firstName && this.contactInfo.lastName && 
        this.contactInfo.addressLine1 && this.contactInfo.suburb &&
        this.contactInfo.state && this.contactInfo.postcode && 
        this.contactInfo.email && 
        this.paymentInfo.cardNumber && this.paymentInfo.securityCode && 
        this.paymentInfo.expiryMonth && this.paymentInfo.expiryYear && 
        this.paymentInfo.cardName &&
        this.isCorrectEmailDomain &&
        !this.$v.contactInfo.firstName.$error && !this.$v.contactInfo.lastName.$error &&
        !this.$v.contactInfo.addressLine1.$error && !this.$v.contactInfo.suburb.$error &&
        !this.$v.contactInfo.state.$error && !this.$v.contactInfo.postcode.$error &&
        !this.$v.contactInfo.email.$error && 
        !this.$v.paymentInfo.cardNumber.$error && !this.$v.paymentInfo.securityCode.$error &&
        !this.$v.paymentInfo.expiryMonth.$error && !this.$v.paymentInfo.expiryYear.$error &&
        !this.$v.paymentInfo.cardName.$error
      );
    },
  },
  methods: {
    onSubmit: async function () {
      this.$v.contactInfo.$touch();
      this.$v.paymentInfo.$touch();

      if (!this.activeConfirmBtn) return;

      const isValidDate = isExpirationDateValid(this.convertMonth(this.paymentInfo.expiryMonth), this.paymentInfo.expiryYear)
      if (!isValidDate && this.paymentInfo.expiryMonth.length > 0 && this.paymentInfo.expiryYear) {
        this.isShowAuthorisedErrMsg = false;
        this.isShowExpiryDateErrMsg = true;
        return;
      } else {
        this.isShowExpiryDateErrMsg = false;
      }

      let publishableKey = '';

      if (!this.$v.contactInfo.$error && !this.$v.paymentInfo.$error) {
        let pinchCredentials;
        try {
          pinchCredentials = await PaymentService.getPinchCredentials(true);
        } catch (error) {
          if (error.response && (error.response.status === 404 || error.response.status === 401)) {
            console.log('err', error)
            pinchCredentials = await PaymentService.getPinchCredentials(true);
          }
        }

        publishableKey = process.env.VUE_APP_BASE_API_ENDPOINT.includes('staging') || process.env.VUE_APP_BASE_API_ENDPOINT.includes('localhost')
          ? pinchCredentials.data.publishableKeyTesting 
          : pinchCredentials.data.publishableKeyProduction;

        // Setup CaptureJs
        const capture = window.Pinch.Capture({
          publishableKey,
        });

        this.isLoadingApi = true;
        // Tokenise Credit Card
        capture.createToken({
          sourceType: "credit-card",
          cardNumber: this.paymentInfo.cardNumber,
          expiryMonth: this.convertMonth(this.paymentInfo.expiryMonth),
          expiryYear: this.paymentInfo.expiryYear,
          cvc: this.paymentInfo.securityCode,
          cardHolderName: this.paymentInfo.cardName
        }).then((result) => {
          if (result && result.token) {
            this.isShowAuthorisedErrMsg = false;
            this.$store.dispatch(SET_PAYMENT_INFO, {
              ...this.payment,
              token: result.token,
            });
            PaymentService.createPayer({
              ...this.payment,
              paymentInfo: {
                cardNumber: '',
                securityCode: '',
                expiryMonth: '',
                expiryYear: '',
                cardName: '',
              }
            })
            .then(({ data }) => {
              if (data && data.id) {
                this.$router.push({ name: "payment-finish" });
              } else {
                this.isShowAuthorisedErrMsg = true;
                this.isLoadingApi = false;
              }
            })
            .catch((error) => {
              this.isLoadingApi = false;
              this.isShowAuthorisedErrMsg = true;
              console.log(error);
            });
          } else {
            this.isLoadingApi = false;
          }
        }).catch((error) => {
          this.isLoadingApi = false;
          if (error) {
            this.isShowAuthorisedErrMsg = true;

            PaymentService.logPayment({
              errMessage: error.errors[0].errorMessage,
              logInfo: {
                sellerEmail: this.contactInfo.email.toLowerCase(),
              }
            })
          }
        });
      }
    },
    getContactFieldErrorMessage(fieldName) {
      const displayName = this.convertToDisplayName(fieldName);
      const field = this.$v.contactInfo[fieldName];
      if (field.$dirty) {
        if (!field.required) {
          return `Please enter your ${displayName}`;
        } else if (!field.valid && fieldName !== 'email' && fieldName !== 'postcode') {
          return "Please enter only alphabet characters, numerics, and spaces!";
        } else if (!field.email && fieldName === 'email') {
          return "Please enter a valid email address!";
        } else if (!this.isCorrectEmailDomain && fieldName === 'email') {
          return "Invalid email address entered!";
        } else if (!field.numeric && fieldName === 'postcode') {
          return "Please enter only numerics!";
        } else if ((!field.minLength || !field.maxLength) && fieldName === 'postcode' ) {
          return "Please enter 4 digits value!";
        } else if (!field.maxLength && fieldName !== 'email' ) {
          return "Please enter 1 to 110 characters!";
        } else if (!field.maxLength && fieldName === 'email') {
          return "Please enter 1 to 256 characters!";
        } else  {
          return ''; // Reset error message if no error
        }
      } else {
        return '';
      }
    },
    getPaymentFieldErrorMessage(fieldName) {
      const displayName = this.convertToDisplayName(fieldName);
      const field = this.$v.paymentInfo[fieldName];
      if (field.$dirty) {
        if (!field.required) {
          return `Please enter your ${displayName}`;
        } else if (!field.numeric && fieldName !== 'cardName') {
          return "Please enter only numerics!";
        } else if ((!field.minLength || !field.maxLength) && fieldName === 'cardNumber') {
          return "Invalid card number";
        } else if ((!field.minLength || !field.maxLength) && fieldName === 'securityCode') {
          return "Invalid security code";
        } else if (!field.between && fieldName === 'expiryYear') {
          return "Invalid expiry year";
        } else if (!field.valid && fieldName === 'cardName') {
          return "Please enter only alphabet characters, numerics, and spaces!";
        } else  {
          return ''; // Reset error message if no error
        }
      } else {
        return '';
      }
    },
    convertToDisplayName(fieldName) {
      return fieldName.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
    },
    convertMonth(month) {
      return new Date(month+'-1-01').getMonth()+1;
    },
    async getEncryptionKey() {
      const queryString = getCookie("qs");
      if (queryString && queryString.indexOf('enc') !== -1) {
        const encKey = await PaymentService.getParamsValue({
          paramsType: 'encryption',
        });
        if (encKey && encKey.data) {
          try {
            const position = queryString.search('enc=');
            let buf = queryString.slice(position + 4);
            buf = decodeURIComponent(buf).replace(/ /g, "+");
            const parts = buf.split(':');
            const encryptedString = parts[1];
            const decrypted = decrypt(encryptedString, atob(encKey.data));
            const urlParams = new URLSearchParams(decrypted);
            if (urlParams) {
              if (urlParams.get("email")) {
                this.$store.dispatch(SET_BUYERS_PORTAL_MAIL, urlParams.get("email"));
              }
              this.$store.dispatch(SET_PAYMENT_INFO, {
                ...this.payment,
                contactInfo: {
                  ...this.contactInfo,
                  firstName: urlParams.get("firstName") || '',
                  lastName: urlParams.get("lastName") || '',
                  email: urlParams.get("email") || '',
                }
              });
            }
            return urlParams;
          } catch (err) {
            console.log("Get Encryption error", err);
          }
        }
      }
    },
    async checkEmailWithDns(email) {
      if (!email.includes("@") || this.$v.contactInfo.email.$error) return;
      const domain = email.split("@")[1];
      try {
        const response = await customerDetailService.resolveDomain(domain);
        this.isCorrectEmailDomain =
          response.status === 200 && response.data ? true : false;
      } catch (error) {
        console.error("Error resolving domain:", error);
        this.isCorrectEmailDomain = false;
      }
    },
  },
  watch: {
    "contactInfo.email": {
      handler: debounce(function (newVal) {
        this.checkEmailWithDns(newVal);
      }, 500),
      immediate: false, // The watcher will not run when the component is created
    },
  },
  async mounted() {
    this.$store.dispatch(GET_NZ_VARIABLES, {
      locid: this.$route.query.locid,
      uxeId: parseInt(this.$route.query.locid) === 2 ? "MycarNZ" : "Mycar",
    });
    await this.getEncryptionKey();
  },
  validations: {
    contactInfo: {
      firstName: {
        required,
        valid: regexName,
        maxLength: maxLength(110),
      },
      lastName: {
        required,
        valid: regexName,
        maxLength: maxLength(110),
      },
      addressLine1: {
        required,
        valid: regexName,
        maxLength: maxLength(110),
      },
      suburb: {
        required,
        valid: regexName,
        maxLength: maxLength(110),
      },
      state: {
        required,
        valid: regexName,
        maxLength: maxLength(110),
      },
      postcode: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      email: {
        required,
        email,
        maxLength: maxLength(256),
      },
    },
    paymentInfo: {
      cardNumber: {
        required: requiredIf(function(){
          return this.paymentInfo.cardNumber.length === 0 && !isValid(this.paymentInfo.cardNumber)
        }),
        numeric,
        minLength: minLength(13),
        maxLength: maxLength(16),
      },
      securityCode: {
        required,
        numeric,
        minLength: minLength(3),
        maxLength: maxLength(4),
      },
      expiryMonth: {
        required
      },
      expiryYear: {
        required,
        numeric,
        between: between(2024, 2040)
      },
      cardName: {
        required,
        valid: regexName,
        maxLength: maxLength(110),
      },
    }
  },
};
</script>

<style lang="scss">
.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.payment {
  padding: 0 20px;
  .payment-instruction {
    .payment-title {
      color: var(--primary-color);
    }
    .payment-content {
      font-size: 16px;
    }
    .payment-note {
      font-weight: normal !important;
      color: var(--quick-tip-text);
      background-color: var(--quick-tip-background);
      border: 1px solid var(--quick-tip-border);
      box-sizing: border-box;
      border-radius: 10px;
      width: 100%;
      height: 108px;
      margin: 10px auto;
      padding: 10px 0;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .heading{
        margin-bottom: 0;
        font-weight: bold !important;
        font-size: 20px;
      }
      ul {
        display: flex;
        margin: 0 auto;
        text-align: left;
        font-size: 16px;
        padding: 0;
        flex-direction: column;
      }
    }
  }
  .payment-info {
    .alert-container {
      img {
        width: 20px;
      }
    }
  }
  .default-btn-confirm {
    outline: none !important;
    width: 100%;
    height: 52px;
    padding: 16px 12px;
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    font-size: 16px;
    border: none;
    color: white;
    flex: 1;
  }
  .btn-confirm {
    background-color: var(--secondary-color);
    cursor: not-allowed;
  }
  .btn-confirm-active {
    background-color: var(--primary-color);
    cursor: pointer;
    &:hover, &:active {
      background: linear-gradient(0deg, rgba(98, 0, 238, 0.08), rgba(98, 0, 238, 0.08)), var(--primary-color);
    }
  }
  .payment-contact {
    color: #463209;
    a {
      color: #463209;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px){
  .payment-note {
    height: 135px !important;
    ul {
      font-size: 14px !important;
      width: 70% !important;
    }
  }
  .payment-info {
    .alert-container {
      .alert-text {
        font-size: 14px !important;
      }
    }
  }
  .payment-content {
    font-size: 15px !important;
  }
}
@media (max-width: 767px) {
  .payment-content {
    font-size: 14px !important;
  }
  .payment-note {
    height: 149px !important;
    .heading {
      font-size: 16px !important;
    }
    ul {
      width: 75% !important;
    }
  }
  .payment-info {
    .alert-container {
      .alert-text {
        font-size: 13px !important;
      }
    }
  }
}
@media (max-width: 990px) {
  .payment-title {
    font-size: 20px !important;
  }
  .payment-contact {
    font-size: 14px !important;
  }
}

@media (max-width: 409px) {
  .payment-note {
    height: 245px !important;
  }
}
@media (min-width: 410px) and (max-width: 549px) {
  .payment-note {
    height: 200px !important;
  }
}
</style>